var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("svgicon", { staticClass: "logo", attrs: { name: "logo" } }),
        _c("svgicon", {
          staticClass: "logo-text",
          attrs: { name: "logo-text" },
        }),
        !_vm.hasLoggedOut
          ? _c("span", [_vm._v("Logging out...")])
          : _c("span", [_vm._v("Logged out successfully")]),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "copyright" },
      [
        _vm._v(" Made with "),
        _c("heart", { attrs: { width: 12 } }),
        _vm._v(" by CodeFish Studio "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }